import React from 'react';
import GoogleMapReact from 'google-map-react';
import TextareaAutosize from 'react-autosize-textarea';
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ReactGA from 'react-ga';

gsap.registerPlugin(ScrollToPlugin);

class App extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      memories: {result: []},
      indexInsert: 0,
      runInsert: false,
      form: {
        "id": 0,
        "memory": '',
        "coords": '',
        "coordsReal": '',
        "year": '',
        "name": ''
      },
      colophon: false,
      firstLetters: []
    };

    this.content = React.createRef();
    this.colophon = React.createRef();

    this.slide0 = React.createRef();
    this.slide1 = React.createRef();
    this.slide2 = React.createRef();
    this.slide3 = React.createRef();
    this.slide4 = React.createRef();

    this.slideSubmit0 = React.createRef();
    this.slideSubmit1 = React.createRef();
    this.slideSubmit2 = React.createRef();
    this.slideSubmit3 = React.createRef();
    this.slideSubmit4 = React.createRef();

    this.slideTextarea0 = React.createRef();
    this.slideTextarea1 = React.createRef();
    this.slideTextarea2 = React.createRef();
    this.slideTextarea3 = React.createRef();

    this.nextInsert = this.nextInsert.bind(this);
    this.goToLetter = this.goToLetter.bind(this);
    this.escFunction = this.escFunction.bind(this);

  }

  componentDidMount() {

    ReactGA.initialize('UA-162987639-1');
    ReactGA.pageview('/');

    fetch("https://www.thefirstmemories.com/api/get.php", { method: 'GET' }).then(res => res.json()).then(
      (result) => {

        result.result.sort((a, b) => {

          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

        });

        var firstLetters = [];

        for (var i = 0; i < result.result.length; i++) {

          if (!firstLetters.includes(result.result[i].name.charAt(0))) {

            firstLetters[firstLetters.length] = result.result[i].name.charAt(0);

          }

        }

        this.setState({ memories: result, firstLetters: firstLetters });

      },
      (error) => {
        console.log(error);
      }
    );

  }

  startInsert(value, input) {

    var timeline = gsap.timeline();

    if (value.length > 0) {

      for (var i = 0; i < 5; i++) {
        timeline.to(this['slideSubmit' + i].current, 0.3, { y: '0px' });
      }

    } else {

      for (i = 0; i < 4; i++) {
        timeline.to(this['slideSubmit' + i].current, 0.3, { y: '50px' });
      }

    }

    this.onInsert(value, input);

  }

  onInsert(value, input) {

    var form = this.state.form;

    form[input] = value;

    this.setState({ form: form });

  }

  nextInsert() {

    if (this.state.runInsert === false) {

      this.setState({ runInsert: true });

      this.setState({ indexInsert: this.state.indexInsert+1 }, () => {

        var timeline = gsap.timeline();

        timeline.eventCallback("onComplete", () => {
          if (this.state.indexInsert < 4) {
            this['slideTextarea' + this.state.indexInsert].current.focus();
            this.setState({ runInsert: false });
          }
        });

        for (var i = 0; i < 5; i++) {

          if (i === this.state.indexInsert-1 || i === this.state.indexInsert) {
            timeline.to(this['slide' + i].current, 0.6, {y: '-' + (this.state.indexInsert*100) + '%'});
          } else {
            timeline.set(this['slide' + i].current, {y: '-' + (this.state.indexInsert*100) + '%'});
          }
        }

      });

    }

  }

  addInsert() {

    this.nextInsert();

    var input = new FormData();

    input.append("memory", this.state.form.memory);
    input.append("coords", this.state.form.coords);
    input.append("year", this.state.form.year);
    input.append("name", this.state.form.name);

    fetch("https://www.thefirstmemories.com/api/set.php", { method: 'POST', body: input }).then(res => res.json()).then(
      (result) => {

        input.append("id", result.result);

        var memories = this.state.memories;

        memories['result'][memories['result'].length] = this.state.form;

        memories.result.sort((a, b) => {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        var firstLetters = [];

        for (var i = 0; i < memories.result.length; i++) {

          if (!firstLetters.includes(memories.result[i].name.charAt(0))) {

            firstLetters[firstLetters.length] = memories.result[i].name.charAt(0);

          }

        }

        this.setState({ memories: memories, firstLetters: firstLetters });

      },
      (error) => {
        console.log(error);
      }
    );

  }

  openColophon() {

    var timeline = gsap.timeline();

    if (this.state.colophon === true) {

      timeline
        .to(this.colophon.current, 0.3, {opacity: '0' })
        .to(this.content.current, 0.3, {filter: 'blur(0px)' }, '-=0.15')
        .set(this.colophon.current, {pointerEvents: 'none' });

      this.setState({ colophon: false });

      document.removeEventListener("keydown", this.escFunction, false);

    } else {

      timeline
        .to(this.content.current, 0.3, {filter: 'blur(5px)' })
        .to(this.colophon.current, 0.3, {opacity: '1' }, '-=0.15')
        .set(this.colophon.current, {pointerEvents: 'auto' });

      this.setState({ colophon: true });

      document.addEventListener("keydown", this.escFunction, false);

    }

  }

  escFunction(event) {

    if (event.keyCode === 27) {

      this.openColophon();

    }

  }

  goToLetter(letter) {

    gsap.to('.memories', { duration: 0.5, scrollTo: { y: '.fl_' + letter, offsetY: 55 } } );

  }

  render() {

    let options = {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: [
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            { "hue": "#000000" },
            { "saturation": -100 },
            { "lightness": -100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            { "hue": "#000000" },
            { "saturation": -100 },
            { "lightness": -100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            { "hue": "#000000" },
            { "saturation": 0 },
            { "lightness": -100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
            { "hue": "#ffffff" },
            { "saturation": -100 },
            { "lightness": 100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
            { "hue": "#000000" },
            { "saturation": -100 },
            { "lightness": -100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "all",
          "stylers": [
            { "hue": "#ffffff" },
            { "saturation": -100 },
            { "lightness": 100 },
            { "visibility": "on" }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            { "hue": "#ffffff" },
            { "saturation": -100 },
            { "lightness": 100 },
            { "visibility": "on" }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels",
          "stylers": [
            { "hue": "#000000" },
            { "saturation": 0 },
            { "lightness": -100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "labels",
          "stylers": [
            { "hue": "#000000" },
            { "saturation": -100 },
            { "lightness": -100 },
            { "visibility": "off" }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            { "hue": "#bbbbbb" },
            { "saturation": -100 },
            { "lightness": 26 },
            { "visibility": "on" }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
            { "hue": "#dddddd" },
            { "saturation": -100 },
            { "lightness": -3 },
            { "visibility": "on" }
          ]
        }
      ]
    }

    return (
      <div className="App">

        <div className="content" ref={ this.content }>

          <div className="memories">

            <div className="title">
              <a href="https://thefirstmemories.com">THE FIRST MEMORIES</a>
            </div>

            <div className="list">

              {this.state.memories.result.map((item, i) => {
                return(
                  <div className={ 'memory fl_' + (item.name.charAt(0)) } key={'memory_' + i}>

                    <div className="name">{ item.name }</div>
                    <div className="year">Year: { item.year }</div>
                    <div className="text">{ item.memory }</div>

                  </div>
                )
              })}

            </div>

            <div className="bar">

              {this.state.firstLetters.map((item, i) => {
                return(
                  <div className="item" key={'item_' + i} onClick={ () => { this.goToLetter(item) } }>
                    { item }
                  </div>
                )
              })}

            </div>

          </div>

          <div className="sidebar">

            <div className="flex">

              <div className="insert">

                <div className="slide" ref={ this.slide0 }>
                  <div className="flex">

                    <div className="table">
                      <div className="table_cell">

                        <div>

                          <div className="label">WHAT IS YOUR FIRST MEMORY?</div>

                          <div className="textarea">
                            <TextareaAutosize ref={ this.slideTextarea0 } placeholder="TYPE HERE" onChange={(e) => this.startInsert(e.target.value, 'memory') } />
                            <div className="submit" ref={ this.slideSubmit0 } onClick={ () => this.nextInsert() }>↓</div>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="slide" ref={ this.slide1 }>
                  <div className="flex">

                    <div className="table">
                      <div className="table_cell">

                        <div>

                          <div className="label">PUT YOUR MEMORY ON THE MAP</div>

                          <div className="textarea">
                            <TextareaAutosize ref={ this.slideTextarea1 } placeholder="E.G. ROME; MOSCOW." onChange={(e) => this.onInsert(e.target.value, 'coords') } />
                            <div className="submit" ref={ this.slideSubmit1 } onClick={ () => this.nextInsert() }>↓</div>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="slide" ref={ this.slide2 }>
                  <div className="flex">

                    <div className="table">
                      <div className="table_cell">

                        <div>

                          <div className="label">WHAT YEAR WAS IT?</div>

                          <div className="textarea">
                            <TextareaAutosize ref={ this.slideTextarea2 } placeholder="E.G. ’90s; 1969; UNKNOWN." onChange={(e) => this.onInsert(e.target.value, 'year') } />
                            <div className="submit" ref={ this.slideSubmit2 } onClick={ () => this.nextInsert() }>↓</div>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="slide" ref={ this.slide3 }>
                  <div className="flex">

                    <div className="table">
                      <div className="table_cell">

                        <div>

                          <div className="label">WHAT’S YOUR NAME?</div>

                          <div className="textarea">
                            <TextareaAutosize ref={ this.slideTextarea3 } placeholder="E.G. Roberto B.; Anonymous; Albert Hofmann." onChange={(e) => this.onInsert(e.target.value, 'name') } />
                            <div className="submit" ref={ this.slideSubmit3 } onClick={ () => this.addInsert() }>↓</div>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="slide" ref={ this.slide4 }>
                  <div className="flex">

                    <div className="table">
                      <div className="table_cell">

                        <div>

                          <div className="label">THANKS FOR YOUR MEMORY.</div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div className="stats">THERE ARE { this.state.memories.result.length } FIRST MEMORIES</div>

              <div className="map">

                <GoogleMapReact
                  options={options}
                  bootstrapURLKeys={{ key: 'AIzaSyCzbF9S2M6IZzHMvdERaqXSTmULF6c6pZw' }}
                  defaultCenter={{ lat: 42.719853, lng: -30.723854 }}
                  defaultZoom={0}
                >

                  {this.state.memories.result.map((item, i) => {
                    if (item.coordsReal) {
                      var lat = item.coordsReal.split(',')[0];
                      var long = item.coordsReal.split(',')[1];
                      return(
                        <div lat={lat} lng={long} key={'map_' + i}>
                          <div className="dot"></div>
                        </div>
                      )
                    }
                  })}

                </GoogleMapReact>

              </div>

            </div>

          </div>

        </div>

        <div className="menu" onClick={ () => this.openColophon() }></div>

        <div className="colophon" ref={ this.colophon }>

          <div className="relative">
            <div className="table">
              <div className="table_cell">

                <div className="item">
                  <u>WHAT IS YOUR FIRST MEMORY</u><br />
                  A PROJECT BY A. PERGOLA
                 </div>

                 <div className="item">
                  <u>DESIGNED AND DEVELOPED WITH LOVE BY</u><br />
                  <a target="_blank" rel="noopener noreferrer" href="https://www.hellodude.it">DUDE</a> (A. BUOSCIO, A. BUSSU, A. PERGOLA)
                </div>

                <div className="item">
                  <u>THANKS TO</u><br />
                  ELENA FORTUNATI
                </div>

                <div className="item">
                  <u>CONTACT ME</u><br />
                  <a href="mailto:andrepergola@gmail.com">ANDREPERGOLA@GMAIL.COM</a>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default App;
